<template>
    <material-card :title="'Preview PI'+order.pi_number">
        <v-simple-table dense>
            <template v-slot:default>
                <thead>
                <tr>
                    <th style="min-width:100px;">WS Item No</th>
                    <th>Photo</th>
                    <th style="min-width:180px;">Desc</th>
                    <th>Item Size</th>
                    <th>Unit</th>
                    <th>Qty/CTN</th>
                    <th>FOB(usd)</th>
                    <th>Carton Measures(cm)</th>
                    <th>Carton CBM (m^2)</th>
                    <th>Gross W. (kg)</th>
                    <th>Net Weight (kg)</th>
                    <th>Order Qty</th>
                    <th>Total Carton</th>
                    <th>Total CBM</th>
                    <th>Total Amount</th>
                    <th style="min-width:250px;">Remarks</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="line in order.orderlines">
                    <td>
                        {{ line.product.erp_item_number }}
                    </td>
                    <td>
                        <v-img
                                v-if="line.product.album && line.product.album.files.length"
                                class="white--text"
                                max-width="150px"
                                max-height="100px"
                                :src="line.product.album.files[0].url"
                        >
                        </v-img>
                    </td>
                    <td>
                        {{ line.product.description.substring(0, 18)+'...' }}
                    </td>
                    <td>
                        {{ line.product.packaging }}
                    </td>
                    <td>
                        {{ line.product.unit }}

                    </td>
                    <td>
                        {{ line.pieces_per_master_carton }}
                    </td>
                    <td>
                        ${{ line.organisation_fob_price }}
                    </td>
                    <td>
                        {{ line.carton_measures_width }} x
                        {{ line.carton_measures_depth }} x
                        {{ line.carton_measures_height }}
                    </td>
                    <td>
                        {{ line.product.item_size_width * line.product.item_size_height * line.product.item_size_depth }}
                    </td>
                    <td>
                        {{ line.product.gross_w }}
                    </td>
                    <td>
                        {{ line.product.net_weight }}
                    </td>
                    <td>
                        {{ line.qty }}
                    </td>
                    <td>
                        {{ line.qty / line.pieces_per_master_carton }}
                    </td>
                    <td>
                        {{ ((line.qty / line.pieces_per_master_carton) * (line.carton_measures_width * line.carton_measures_height * line.carton_measures_depth)) }}
                    </td>
                    <td>
                        ${{ (line.qty * line.organisation_fob_price).toFixed(2) }}
                    </td>
                    <td>
                        {{ line.remarks }}
                    </td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>

        <v-simple-table class="mb-8">
            <tr>
                <th>
                    Customer Total:
                </th>
                <th>
                    ${{ order.sales_pi.toFixed(2) }}
                </th>
            </tr>
        </v-simple-table>

        <v-btn
              color="blue-grey darken-4"
              block outlined
              :disabled="order.is_loading_pi"
              @click="download_generated_pi_or_quote($event, order)"
            >
            <v-icon>mdi-inbox-arrow-down</v-icon>
            Download
        </v-btn>

    </material-card>

</template>
<style lang="scss">

</style>

<script>
    import {gen_order_pi, gen_order_quote, gen_breakdown, gen_po} from '@/plugins/excel_pi_po_generator';

    export default {
        components: {},
        data() {
            return {
                order_old:{},
                order:{},
                is_loading: false,
                partners:[]
            }
        },
        created() {
            this.invoice_id = this.$route.params.invoice_id;
            this.load_order();
        },
        methods: {
            async load_order() {
                this.is_loading = true;
                await this.load_partners();
                console.log(this.partners);
                this.order = await this.$hget('order/' + this.invoice_id).then(response => response.data);
                let self = this;
                this.order.orderlines.forEach(function (i) {
                    i.factory_fob_price = i.factory_fob_price.toFixed(3);
                    i.organisation_fob_price = i.organisation_fob_price.toFixed(3);
                    let dp_pc = i.department_percentage;
                });
                this.order_old = Object.assign(this.order, {});
                console.log(this.order);
            },
            async load_partners() {
                this.partners = await this.$hget('admins/partners/').then(response => response.data);
            },
            async download_generated_pi_or_quote(event, order){
                order.is_loading_pi = true;
                //TODO: Get lines for factory
                let pi = await this.$hget('/admins/order/' + order.pi_number + '/pi/?type=pi').then(r => r.data);
                let worksheet_buffer = (order.stage > 5)? await gen_order_pi(this, pi, pi.order_lines) : await gen_order_quote(this, pi, pi.order_lines);
                await saveAs(new Blob([worksheet_buffer]), order.pi_number + ".xlsx");
                order.is_loading_pi = false;
            },
        }
    }
</script>
